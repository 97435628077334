body {
  margin: 0;
  font-family: Verdana, sans-serif;
}

.container_centerpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.container_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.flexRow_center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}

.flexRow_start {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.flexRow_end {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
/* // ----------------------------- size */
.marginLeft_1rem {
  margin-left: 1rem;
}
.marginLeft_2rem {
  margin-left: 2rem;
}

.width_5rem {
  width: 5rem;
}

.gap {
  gap: 1rem;
}

.gap_dot2rem {
  gap: 0.2rem;
}

.padding_1rem {
  padding: 1rem;
}
.margin_1rem {
  margin: 1rem;
}
.margin_1rem_leftright {
  margin: 0rem 1rem 0rem 1rem;
}

.selectedValue {
  color: orange;
  /* color: white; */
  /* background-color: orange; */
  padding: 0px 15px 0px 15px;
  /* border-radius: 20px; */
}

.normalContainerBG {
  padding: 1rem;
  border-radius: 1rem;
  background-color: rgb(249, 237, 222);
}

.subLv1 {
  margin-left: 5rem;
  border-radius: 1rem;

  padding: 1rem;
  background-color: rgb(249, 223, 189);
}

.padding_2rem {
  padding: 1rem;
}

.dis_container {
  display: flex;
  flex-direction: column;

  background-color: rgb(255, 249, 238);
  padding: 1rem;

  margin-bottom: 1rem;
}

.dis_profilPic {
  border-radius: 30px;
  height: 1.5rem;

  cursor: pointer;
}

.dis_date {
  color: lightslategray;
  padding-left: 2rem;
}

.dis_comment {
  display: flex;
  /* background-color: aqua; */
}

.gap_10 {
  gap: 10px;
}

.text_warp {
  word-wrap: break-word;
}

.text_ColorBlack {
  color: black;
}

.text_outlet {
  padding: 10px;
  background-color: rgb(255, 149, 0);
  border-radius: 0px 60px 60px 0px;
}
.text_category {
  padding: 10px;
  background-color: rgb(255, 196, 0);
  border-radius: 0px 60px 60px 0px;
}
.text_option {
  padding: 10px;
  background-color: rgb(255, 251, 0);
  border-radius: 0px 60px 60px 0px;
}
.text_option2 {
  padding: 10px;
  background-color: rgb(170, 255, 0);
  border-radius: 0px 60px 60px 0px;
}
.text_remark {
  padding: 10px;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 0px 60px 60px 0px;
}

.text_TableTitle {
  font-size: 2rem;
  color: black;
  padding: 1rem;
}

.text_Tips {
  /* background-color: rgb(253, 234, 215); */
  color: rgb(90, 90, 90);
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 5rem;
}

.text_Aging {
  margin: 5px;
  font-size: 10px;
}
.text_Aging_Remain_Day {
  margin-top: 5px;
  font-size: 1rem;
  color: black;
  background-color: rgb(255, 136, 0);
  padding: 0.5rem;
  border-radius: 0.2rem;
}
.text_Aging_Remain_Hour {
  margin-top: 5px;
  font-size: 1rem;
  color: black;
  background-color: gold;
  padding: 0.5rem;
  border-radius: 0.2rem;
}
.text_Aging_Remain_Min {
  margin-top: 5px;
  font-size: 1rem;
  color: black;
  background-color: rgb(152, 253, 0);
  padding: 0.5rem;
  border-radius: 0.2rem;
}

.text_Gold {
  color: gold;
}

.ico_profilPic {
  border-radius: 5rem;
  height: 2rem;
  margin: 0.5rem;

  cursor: pointer;
}

.ico_profilPic_smallest {
  border-radius: 5rem;
  height: 1rem;
  margin: 0.5rem;

  cursor: pointer;
}

.btn_TableEdit {
  background-color: rgba(0, 157, 255, 1);
  color: black;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  /* padding: 1rem; */
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 250ms ease-in-out;
}
.btn_TableEdit:hover {
  background-color: rgb(0, 54, 88);

  color: white;
  /* margin: 2rem; */
  /* padding: 3rem;
  border-radius: 1rem; */
}

.notif_TiketID {
  background-color: #2bc3ff;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.notif_ArrowPointRight {
  /* background-color: #2bc3ff; */
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.notif_Pending {
  background-color: red;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.notif_Troubleshooting {
  background-color: gold;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.notif_Solved {
  background-color: lime;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.dailySummit_Radio {
  background-color: rgb(216, 216, 216);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem;
}

.versionContainer {
  display: flex;
  justify-content: center;
}

.logoContainer {
  width: 100%;
  /* background-color: rgb(141, 34, 34); */
  display: flex;
  justify-content: center;
}

.LogoText1 {
  color: #ff6f00;
  font-size: 30px;
}
.LogoText2 {
  color: #ff9500;
  font-size: 30px;
}
.LogoText3 {
  color: #ffb300;
  font-size: 30px;
}

/* //--------------------------------------------- status btn */
.dis_container_inStatusbtn {
  /* min-width: 12rem; */
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: rgb(255, 249, 238);
  /* padding: 0rem; */
  width: 100%;
  border-radius: 0.2rem;

  /* margin-bottom: 1rem; */
}

.btn_Pending {
  min-width: 14rem;
  cursor: pointer;

  background-color: rgb(125, 0, 0);
  color: white;
  border-radius: 5px;
  padding: 10px;

  transition: 250ms ease-in-out;
}
.btn_Pending:hover {
  background-color: rgb(255, 0, 0);
  color: black;
}

.btn_troubleshooting {
  min-width: 14rem;
  cursor: pointer;

  background-color: rgb(182, 154, 0);
  color: black;
  border-radius: 5px;
  padding: 10px;

  transition: 250ms ease-in-out;
}
.btn_troubleshooting:hover {
  background-color: rgb(255, 217, 0);
  color: black;
}

.btn_solved {
  min-width: 14rem;
  cursor: pointer;

  background-color: rgb(139, 184, 3);
  color: black;
  border-radius: 5px;
  padding: 10px;

  transition: 250ms ease-in-out;
}

.btn_solved:hover {
  background-color: rgb(191, 255, 0);
  color: black;
}

.btn_NULL {
  min-width: 14rem;
  cursor: pointer;

  background-color: rgb(65, 65, 65);

  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 10px;

  transition: 250ms ease-in-out;
}

.btn_NULL:hover {
  background-color: rgb(6, 247, 255);
  color: rgb(0, 0, 0);
}

.icon1 {
  background-color: black;
  color: greenyellow;
  border-radius: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
}

.MyTicketSummary_RB_Container {
  background-color: #fce8cb;
  border-radius: 1rem;
  padding: 1rem;

  cursor: pointer;
}

.btn_RB_Type1 {
  background-color: #f9f9f9;
  padding: 1rem 1rem 1rem 5rem;
  margin-left: -3rem;
  border-radius: 1rem;

  cursor: pointer;
}

.btn_RB_Type2 {
  background-color: rgb(98, 1, 115);
  color: rgb(250, 199, 255);

  padding: 1rem 1rem 1rem 1rem;
  /* margin-top: -3rem; */
  /* margin-left: -4rem; */
  margin-bottom: 1.8rem;
  border-radius: 0rem 0rem 1rem 1rem;

  cursor: pointer;

  transition: 250ms ease-in-out;
}
.btn_RB_Type2:hover {
  background-color: rgb(167, 0, 196);
  color: rgb(255, 255, 255);
}

.btn_RB_Type3 {
  background-color: rgb(42, 70, 0);
  color: rgb(232, 255, 199);

  padding: 1.5rem 0rem 0.5rem 4rem;
  margin-top: -3rem;
  margin-left: -4rem;
  border-radius: 0rem 0rem 1rem 1rem;

  cursor: pointer;

  transition: 250ms ease-in-out;
}
.btn_RB_Type3:hover {
  background-color: rgb(99, 165, 0);
  color: rgb(255, 255, 255);
}

/* //--------------------------------------- text */
.text_fontzie_20 {
  font-size: 20px;
}
.text_fontzie_2rem {
  font-size: 2rem;
}
.text_fontzie_1rem {
  font-size: 1rem;
}
.text_fontzie_d5rem {
  font-size: 0.5rem;
}
.glo_FontWeight_600 {
  font-weight: 600;
}

.glo_FontSize_2rem {
  font-size: 2rem;
}
.glo_FontSize_1rem {
  font-size: 1rem;
}

/* //--------------------------------------- ESL */
.table_container {
  /* background-color: rgb(171, 88, 88); */
  display: flex;
  flex-direction: row;
}

.table_setting {
  background-color: rgb(20, 19, 15);
  width: 100%;
  color: beige;
}

.table_txt_bold {
  font-weight: 700;
}

.table_txt_normal {
  font-weight: 300;
}

.table_selected {
  color: black;
  background-color: rgb(255, 255, 255);
}
.table_text_yellow {
  color: gold;
}
.table_text_gift {
  color: gold;
  font-size: 0.5rem;
  margin-left: 0.5rem;
  width: 1rem;
}
.table_text_redsea {
  color: rgb(255, 0, 0);
  font-size: 0.5rem;
  margin-left: 0.5rem;
  width: 1rem;
}

.body {
  background-color: rgb(20, 19, 15);
}

.table_bg_1 {
  background-color: rgb(0, 0, 0);
}
